<template>
  <section class="MyTeamEdit-View">
    <SpaceDescription>
      <Headline :size="1">Edit existing person</Headline>
    </SpaceDescription>

    <div v-if="model && !requestSuccessful" class="MyTeamEdit-View-wrapper">
      <PersonForm
        :model="model"
        :request="request"
        :invalidFields="invalidFields"
      />
      <SectionControls
        button-left-text="Back"
        button-left-type="secondary"
        @button-left-click="navigateToPreviousPage()"
        button-right-text="Save"
        :button-right-disabled="model.containsEmptyKeys(['firstName', 'lastName', 'birthDate'])"
        @button-right-click="submit()"
      />
    </div>

    <div v-if="requestSuccessful">
      <SectionStatus message="Your changes were saved successfully!"/>
      <SectionControls
        :button-left-text="'Back to Overview'"
        @button-left-click="navigateToOverview()"
        :button-right-text="'Continue editing'"
        @button-right-click="initialiseState()"
      />
    </div>
  </section>
</template>

<script>
import {DtoApiEditPersonDetailsRequest} from "@/classes/dto/api/person/dto.api.edit-person-details.request";
import {DtoApiGetPersonDetailsRequest} from "@/classes/dto/api/person/dto.api.get-person-details.request";
import SpaceDescription from "@/components/SpaceDescription/SpaceDescription.component";
import Headline from "@/components/Headline/Headline.component";
import RouterNames from "@/router/route.names";
import PersonForm from "@/components/Form/PersonForm.component";
import SectionStatus from "@/components/Section/SectionStatus.component";
import SectionControls from "@/components/Section/SectionControls.component";
import get from "lodash.get";

export default {
  name: 'MyTeamEdit-View',
  data: () => ({
    model: null,
    fetch: null,
    request: null,
    invalidFields: null,
    referer: null,
  }),
  components: {
    SpaceDescription,
    Headline,
    PersonForm,
    SectionStatus,
    SectionControls
  },
  computed: {
    permissions() {return this.$store.getters['user/permissions']},
    requestSuccessful() {
      if (!this.request) return false;
      return (this.request.$status === 200) && (this.request.isSuccessful);
    }
  },
  watch: {
    permissions() {this.checkPermissions()}
  },
  created() {
    this.checkPermissions();
    this.initialiseState();
  },
  methods: {
    checkPermissions() {
      const permitted = this.permissions.includes('persons:updateAPI') && this.$store.getters['user/haveAccessToAccreditation'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    },
    async submit() {
      const {personId} = get(this, '$router.currentRoute.params', {});
      const organizationId = this.$store.state.user.account.id;
      const essentialsContainEmptyValues = this.model.containsEmptyKeys(['firstName', 'lastName', 'birthDate']);
      if (essentialsContainEmptyValues) return null;

      this.request = await this.request.$editPersonDetails(organizationId, personId, this.model);

      if(this.$route.params.fromAccreditationCreatePage) {
        this.navigateToCreateAccreditation()
      }
    },
    async initialiseState() {
      const {personId, invalidFields} = get(this, '$router.currentRoute.params', {});
      this.invalidFields = (invalidFields && invalidFields.length) ? invalidFields : null
      this.request = new DtoApiEditPersonDetailsRequest();
      this.fetch = new DtoApiGetPersonDetailsRequest();
      this.fetch = await this.fetch.$getPersonDetails(personId);
      this.model = this.fetch.$toTeamMemberModel();
    },
    navigateToOverview() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonList;
      if(this.referer && this.referer.name === name){
        this.$router.push(this.referer);
      }
      else {
        this.$router.push({name});
      }
    },
    navigateToCreateAccreditation() {
      this.$router.push({name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequest})
    },
    navigateToPreviousPage() {
      if(this.$route.params.fromAccreditationCreatePage) {
        this.navigateToCreateAccreditation()
      } else {
        this.navigateToOverview();
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.referer = from
    })
  }
}
</script>

<style lang="scss">
@import "../../../../styles/mixins/ellipsis.mixin";

.MyTeamEdit-View {
  min-width: 560px;

  .headline-component {
    margin-top: 0 !important
  }

  &-error {
    color: red;
    font-weight: bold;
    position: absolute;
    top: -20px;
    left: 22px;
  }

  &-wrapper {
    position: relative;
  }
}
</style>
