<template>
  <section class="StatusSection">
    <Section class="StatusSection__section">
      <Headline
        :class="[
          'StatusSection__section-headline',
          'StatusSection__section-headline_centered'
        ]"
        :size="3"
      >{{ message }}
      </Headline>
      <div class="StatusSection__section-check-container">
        <img
          class="StatusSection__section-check"
          :src="$static.icons.check"
          alt=""
        />
      </div>
    </Section>
  </section>
</template>

<script>
import set from "lodash.set";
import icon from "@/assets/DDMCAF/images/check.svg";
import Section from "@/components/Section/Section.component";
import Headline from "@/components/Headline/Headline.component";

export default {
  name: 'StatusSection',
  components: {Section, Headline},
  props: {
    message: {type: String, required: true},
  },
  created() {
    set(this, '$static.icons.check', icon);
  }
}
</script>

<style lang="scss">
.StatusSection {
  &__section {

    &-headline {
      display: flex;
      &_centered {justify-content: center}
    }

    &-check {
      width: 100px;
      margin: auto;

      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
