<template>
  <section class="InputImage form-group">
    <div class="row">
      <div class="col-sm-4">
        <InputLayout
            :input-id="$static.input.id"
            :label="label"
            :subtext="subtext"
            :arrangement="arrangement"
            :error="errorMessage"
        >
        </InputLayout>
      </div>
      <div class="col-sm-8">
        <div class="InputImage__content">
          <div
              ref="dropzone"
              v-if="!image"
              class="InputImage__content-box"
              :class="{'InputImage__content-box_hover': hovered}"
              @click="callInput()"
              @drop.prevent="callDrop($event)"
              @dragenter.prevent="hovered = true"
              @dragover.prevent
              @dragend="hovered = false"
              @dragleave="hovered = false"
          ></div>
          <input
              class="InputImage__input"
              ref="input"
              type="file"
              accept="image/x-png,image/jpeg"
              :id="$static.input.id"
              @change="inputChange($event)"
          />
          <img
              @click="callInput()"
              v-if="image"
              :class="{'InputImage__content-image': true, 'error': errorState }"
              :src="uncached(image)"
              @drop.prevent="callDrop($event)"
              @dragenter.prevent="hovered = true"
              @dragover.prevent
              @dragend="hovered = false"
              @dragleave="hovered = false"
          />
          <Button class="InputImage__content-button" type="secondary" @click="callInput()">Choose File</Button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4 col-xl-4 d-md-block d-lg-none d-xl-block"></div>
      <div class="col-xl-8">
        <slot :name="`hint`"></slot>
      </div>
    </div>

  </section>
</template>

<script>
import InputLayout from "@/components/Inputs/components/InputLayout.component";
import InputMixin from "@/components/Inputs/mixins/input.mixin";
import LayoutMixin from "@/components/Inputs/mixins/layout.mixin";
import Button from '@/components/Button/Button.component';

export default {
  name: 'DDMCAF-InputImage',
  components: {InputLayout, Button},
  mixins: [InputMixin, LayoutMixin],
  data: () => ({ image: null, hovered: false, errorMessage: '', errorState:false }),
  props: ({ value: String }),
  created() {
    this.image = this.value;
  },
  watch: {
    value(value) {this.image = value},
    error(value) { this.errorMessage = value }
  },
  methods: {
    callInput() {
      this.$refs.input.click();
    },
    callDrop(event) {
      if (!event.dataTransfer.items.length) return;

      const [item] = event.dataTransfer.items;
      const file = item.getAsFile();
      this.inputHandleFile(file);
    },
    uncached(image) {
      var url = new URL(image);
      if (url.protocol === 'data:') {
        return image;
      }
      url.searchParams.append('_', (new Date).getTime());
      return url.toString();
    },
    inputChange(event) {
      const [file] = event.target.files;
      this.inputHandleFile(file);
    },
    inputHandleFile(file) {
      if (!file) return;
      const maxSize= 512*1024;
      this.image = '';
      this.errorState = false;
      this.errorMessage = '';
      const isImage = ['image/png', 'image/jpeg'].includes(file.type);
      if (!isImage) return;
      if (file.size > maxSize) {
        this.errorMessage = 'File size too large';
        this.errorState = true;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.image = reader.result;
        this.$emit('input', this.image);
      }
      reader.readAsDataURL(file);
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";
.InputImage {
  &__input {
    display: none;
  }

  &__content {
    display: flex;
    align-items: center;

    &-box {
      width: 200px;
      height: 43px;
      background-color: white;
      border: 1px solid #D8DAE3;
      border-radius: 12px;

      &_hover {
        background-color: #F4F6FC;
      }
    }

    &-image {
      width: 200px;
      border: 1px solid #D8DAE3;
      border-radius: 12px;
      &.error {
        border: solid 1px $danger-color;
      }
    }

    &-button {
      margin-left: 20px
    }
  }
}
</style>
