<template>
  <section class="PersonForm">
    <div v-if="invalidFields" class="MyTeamEdit-View-error">
      These fields are required: {{invalidFields.map(this.getName).join(', ')}}
    </div>
    <Section class="PersonForm__section form-horizontal">
      <div>
        <Headline
            class="PersonForm__section-headline row"
            :size="3"
        >Personal Information
        </Headline>
        <div class="PersonForm__section-content row">
          <div class="col-sm-9">

            <Input
                label="First Name *"
                subtext="(as in passport)"
                placeholder="Type First Name"
                v-model="model.firstName"
                :error="getError('firstName')"
            />
            <Input
                label="Last Name *"
                subtext="(as in passport)"
                placeholder="Type Last Name"
                v-model="model.lastName"
                :error="getError('lastName')"
            />
            <InputDropdown
                label="Gender"
                placeholder="Select gender"
                :options="['male', 'female', 'other']"
                :error="getError('gender')"
                v-model="model.gender"
            />
            <InputDatepicker
                label="Date of Birth *"
                placeholder="DD.MM.YYYY"
                v-model="model.birthDate"
                :error="getError('birthDate')"
            />
          </div>
        </div>

        <Headline
            class="PersonForm__section-headline row"
            :size="3"
        >Identification Information
        </Headline>
        <div class="PersonForm__section-content row">
          <div class="col-sm-9">
            <InputDropdown
                label="Nationality *"
                placeholder="Select nationality"
                track-by="id"
                track-label="country"
                :options="this.countryOptions"
                :searchable="true"
                :error="getError('nationality') + getError('nationalityCountryId')"
                v-model="model.nationality"
            />
            <Input
                label="Passport / ID Number *"
                placeholder="Type passport number"
                v-model="model.passportNumber"
                :error="getError('passportNumber')"
            />
            <InputDatepicker
                label="Passport / ID Expiry Date"
                placeholder="Select date of expiry"
                v-model="model.passportExpiryDate"
                :error="getError('passportExpiryDate')"
            />
            <InputImage
                label="Accreditation Photo *"
                subtext="Format: JPEG, PNG - less than 512 kilobytes"
                v-model="model.accreditationPhoto"
                :error="getError('accreditationPhoto') + getError('accreditationPhotoId')"
            >
              <template v-slot:hint="slot">
                <div class="file-hint">
                <div class="row">
                  <div class="col-md-4">
                    <div class="file-hint-image">
                      <img src="@/assets/dummy-accred-photo-1.png" alt="dummy" />
                      <p class="text-center mt-1">Sample</p>
                    </div>
                  </div>
                  <div class="col-md-8 mt-3 mt-md-0">
                    <p>- Photo file size: less than 512 KB</p>
                    <p>- Photo type: JPG, JPEG, PNG</p>
                    <p>- Photo background: plain white recommended</p>
                    <p>- No selfies, no glasses, no hats, no graphic or text</p>
                    <p>- Photo should be in color, clear and in focus</p>
                    <p class="red-text"> Incorrect photo will be rejected!</p>
                  </div>
                </div>
              </div>
              </template>
            </InputImage>
            <InputImage
                label="Copy of Passport / ID *"
                subtext="Format: JPEG, PNG - less than 512 kilobytes"
                v-model="model.passportPhoto"
                :error="getError('passportPhoto')"
            >
              <template v-slot:hint="slot">
                <div class="file-hint">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="file-hint-image">
                        <img src="@/assets/dummy-passport-photo-1.png" alt="dummy" width="200"  />
                        <p class="text-center mt-1">Sample</p>
                      </div>
                    </div>
                    <div class="col-md-8 mt-3 mt-md-0">
                      <p>- File size: less than 512 KB</p>
                      <p>- File type: JPG, JPEG, PNG</p>
                      <p>- Passport / ID scan should be in color, clear and in focus</p>
                      <p class="red-text"> Incorrect file will be rejected!</p>
                    </div>
                  </div>
                </div>
              </template>
            </InputImage>
            <InputFiles
                label="Additional Documents"
                subtext="Format: .pdf - less than 512 kilobytes"
                v-model="model.documents"
                :error="getError('documents')"
            >
              <template v-slot:hint="slot">
                  <div class="file-hint">
                    <p>- Document file size: less than 512 KB</p>
                    <p>- File type: JPG, JPEG, PNG, PDF </p>
                    <p class="red-text">Incorrect files will be rejected!</p>
                  </div>
              </template>
            </InputFiles>

          </div>
        </div>

        <Headline class="PersonForm__section-headline row" :size="3">Contact Information</Headline>
        <div class="PersonForm__section-content row">
          <div class="col-sm-9">
            <Input
                label="Email"
                placeholder="Type email address"
                v-model="model.email"
                :error="getError('email')"
            />
            <InputPhone
                label="Mobile"
                :value="model.phone"
                @input="(model.phone = $event)"
                @country="(model.phoneCountry = $event)"
                :error="getError('phone')"
            />
          </div>
        </div>

      </div>
    </Section>
  </section>
</template>

<script>
import {TeamMemberModel} from "@/classes/models/TeamMember.model";
import {ApiRequestor} from "@/classes/requestors/api.requestor";
import Section from "@/components/Section/Section.component";
import Headline from "@/components/Headline/Headline.component";
import Input from "@/components/Inputs/Input.component";
import InputDatepicker from "@/components/Inputs/InputDatepicker.component";
import InputDropdown from "@/components/Inputs/InputDropdown.component";
import InputImage from "@/components/Inputs/InputImage.component";
import InputPdf from "@/components/Inputs/InputPdf.component";
import InputPhone from "@/components/Inputs/InputPhone.component";
import InputFiles from "@/components/Inputs/InputFiles.component";
import get from "lodash.get";

export default {
  name: 'PersonForm',
  components: {
    Section,
    Headline,
    Input,
    InputDatepicker,
    InputDropdown,
    InputImage,
    InputPhone,
    InputFiles,
    InputPdf
  },
  props: {
    model: {type: TeamMemberModel, required: true},
    request: {type: ApiRequestor, required: true},
    invalidFields: {type: Array, required: false},
  },
  computed: {
    countryOptions() {
      return get(this, '$store.state.countriesList.countryOptions')
    },
  },
  methods: {
    getError(key) {
      let message = get(this, `request.$response.data.data.errors.${key}[0]`);
      let requestSuccessful = get(this, 'request.isSuccessful');
      if (this.invalidFields && this.invalidFields.indexOf(key) > -1 && !message) {
        message = 'This field is required.'
      }
      if (requestSuccessful || !message) return '';
      return message;
    },
    getName(key) {
      return {
        firstName: 'First Name',
        lastName: 'Last Name',
        birthDate: 'Date Of Birth',
        gender: 'Gender',
        nationality: 'Nationality',
        nationalityCountryId: 'Nationality',
        passportNumber: 'Passport / ID Number ',
        passportExpiryDate: 'Passport / ID Expiry Date',
        accreditationPhoto: 'Accreditation Photo',
        accreditationPhotoId: 'Accreditation Photo',
        passportPhoto: 'Copy of Passport / ID',
        documents: 'Additional Documents',
      }[key] || key;
    },
    fetchCountries(data) {
      this.$store.dispatch('countriesList/getCountries', data)
    },
  },
  created() {
    this.fetchCountries()
  },
}
</script>

<style lang="scss">
@import "src/styles/mixins/ellipsis.mixin";

.PersonForm {
  .file-hint {
    padding: 5px 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 18px;

    &-image {
      max-width: 160px;
      min-width: 90px;

      img {
        width: 100%;
      }
    }

    .red-text {
      color:#ff0000;
      font-weight: 600;
    }
  }

  .PersonForm__section {
    padding-left: 40px;
  }

  .InputLayout__error {
    max-width: 150px;
  }

  .multiselect__option,
  .multiselect__single {
    text-transform: capitalize;
  }

  &__section {

    &-headline {
      display: flex;

      &_centered {
        justify-content: center;
      }
    }

    &-check {
      width: 100px;
      margin: auto;

      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
