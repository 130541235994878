import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {TeamMemberModel} from "@/classes/models/TeamMember.model";

export class DtoApiGetPersonDetailsRequest extends ApiRequestor {
  $getPersonDetails(personId) {
    return this.$http('GET', `persons/${personId}`);
  }

  $toTeamMemberModel() {
    const instance = new TeamMemberModel();
    let personData = this.data;

    [
      'firstName',
      'gender',
      'lastName',
      'passportName',
      'passportNumber',
      'passportPhoto',
      'accreditationPhoto',
      'email',
      'phone',
      'documents'
    ].forEach(key => instance[key] = personData[key]);
    ['passportExpiryDate', 'birthDate'].forEach(key => instance[key] = personData[key] ? new Date(personData[key]) : null);

    if (personData.nationality) {
      instance.nationality = {
        id: personData.nationality.countryId,
        country: personData.nationality.name
      }
    }

    instance.organisation = personData.organization
    instance.id = personData.personId;
    return instance;
  }
}
