import {ApiRequestor} from "@/classes/requestors/api.requestor";
import {TeamMemberModel} from "@/classes/models/TeamMember.model";
import {PhoneNumberUtil} from "google-libphonenumber";

export class DtoApiEditPersonDetailsRequest extends ApiRequestor {
  $editPersonDetails(organizationId, personId, model = new TeamMemberModel()) {
    const {firstName, lastName} = model;
    const data = model
      .removeEmptyKeys()
      .formatDateValues(['birthDate', 'passportExpiryDate']);

    const hasPassPhotoBase64 = new RegExp('base64', 'i').test(data.passportPhoto);
    if (hasPassPhotoBase64) data.passportPhotoEncoded = data.passportPhoto;
    delete data.passportPhoto;

    const hasAccredPhotoBase64 = new RegExp('base64', 'i').test(data.accreditationPhoto);
    if (hasAccredPhotoBase64) data.accreditationPhotoEncoded = data.accreditationPhoto;
    delete data.accreditationPhoto;

    data.passportName = `${firstName} ${lastName}`;
    if (data.nationality) data.nationality = data.nationality.A3;
    delete data.phoneCountry;
    data.phone = data.phone || '';
    const payload = {organizationId, ...data};

    return this.$http('POST', `persons/${personId}`, {payload});
  }
}
