<template>
  <section :class="{ InputDatepicker: true, 'form-group': true, row: !!label }">

    <div class="col-sm-4" v-if="label">
    <InputLayout
      :input-id="$static.input.id"
      :label="label"
      :subtext="subtext"
      :arrangement="arrangement"
      :error="error"
    ></InputLayout>
    </div>
    <div class="col-sm-8">
      <DatePicker
        :value="value"
        :disabled="disableInput"
        format="DD.MM.YYYY"
        v-bind="{...$props, ...$attrs}"
        @input="eventEmitterInput($event)"
      >
      </DatePicker>
    </div>

  </section>
</template>

<script>
import InputLayout from './components/InputLayout.component';
import DatePicker from 'vue2-datepicker';
import InputMixin from "@/components/Inputs/mixins/input.mixin";
import LayoutMixin from "@/components/Inputs/mixins/layout.mixin";
import Icon from '@/components/Icon/Icon.component';
import includes from "lodash.includes";

export default {
  name: 'DDMCAF-InputDatepicker',
  components: {InputLayout, DatePicker},
  mixins: [InputMixin, LayoutMixin],
  props: {
    arrangement: {type: String, default: 'horizontal', validator: value => includes(['horizontal', 'vertical'], value)},
    value: {type: null, default: ''},
    placeholder: {type: String, default: ''},
    label: {type: String, default: ''},
    disableInput: {type: Boolean, default: false},
  },
}
</script>

<style lang="scss">
.InputDatepicker {

  .mx-icon-calendar {right: 12px}
  .mx-icon-calendar > svg {transform: scale(1.24)}
  .mx-datepicker { width: 200px }
  .mx-input {
    height: 43px !important;
    font-size: 12px;
    font-weight: 700;
    border: 1px solid #D8DAE3 !important;
    box-shadow: none !important;
    border-radius: 12px;
    padding: 12px 16px 12px 16.5px;
    color: #383838;
    margin-bottom: 0;

    &::placeholder {
      color: #AFB1BC;
      font-weight: 600;
    }
  }
}
.mx-datepicker-main { transition-duration: 0s !important; }
.mx-table {
  .cell { vertical-align: middle }
}
.mx-btn {
  border: none !important;
  outline: none !important;
}
</style>
