<template>
  <section class="InputPdf">
    <InputLayout
      :input-id="$static.input.id"
      :label="label"
      :subtext="subtext"
      :arrangement="arrangement"
      :error="error"
    >
      <div class="InputPdf__dropzone" @click="callInput()">
        <div class="InputPdf__dropzone-background"></div>
        <div class="InputPdf__dropzone-content-empty"></div>
        <pdf :src="typedByteArray" :style="{width: '300px'}"/>
      </div>
      <input
        hidden
        accept=".pdf"
        type="file"
        ref="input"
        :id="$static.input.id"
        @change="inputChange($event)"
      />
    </InputLayout>
  </section>
</template>

<script>
import InputMixin from "@/components/Inputs/mixins/input.mixin";
import LayoutMixin from "@/components/Inputs/mixins/layout.mixin";
import InputLayout from "@/components/Inputs/components/InputLayout.component";
import pdf from 'vue-pdf';

export default {
  name: 'DDMCAF-InputPdf',
  components: {InputLayout, pdf},
  mixins: [InputMixin, LayoutMixin],
  data: () => ({typedByteArray: null}),
  methods: {
    callInput() {
      this.$refs.input.click();
    },
    inputChange(event) {
      const [file] = event.target.files;
      if (!file) return;

      const isPdf = (file.type === "application/pdf");
      if (!isPdf) return;

      const reader = new FileReader();
      reader.onload = () => this.typedByteArray = new Uint8Array(reader.result);
      reader.readAsArrayBuffer(file);
    }
  },
}
</script>

<style lang="scss">
@import "src/styles/mixins/fill.mixin";

.InputPdf {
  &__dropzone {
    position: relative;
    display: flex;
    width: 300px;
    min-height: 300px;
    border: 1px solid #e2e3ea !important;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    &-background {
      @include fill();
      background-color: white;
      z-index: 0;
    }

    &-content-empty {
      @include fill();
      width: calc(100% - 2.5em);
      height: calc(100% - 2.5em);
      margin: auto;
      border: 3px dashed rgba(66,66,66,.15);
    }


  }
}
</style>
